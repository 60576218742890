import React from "react"

import Layout from "components/Layout"
import SingleHero from "components/SingleHero"
import Breadcrumbs from "components/Breadcrumbs"
import SubpageBack from "components/SubpageBack"

import { SingleContent } from "page_components/team"

const Team = ({ pageContext, location }) => {
  return (
    <Layout
      location={location}
      translations={pageContext?.data?.data?.translations}
      seo={{ title: pageContext.data.title }}
    >
      <SingleHero
        title={pageContext.data.title}
        subtitle={pageContext.data.data.acfTeam.position}
        isTeam
      />
      <Breadcrumbs
        parent={{ title: "Zespół", url: "/zespol/" }}
        title={pageContext.data.title}
      />
      <SingleContent data={pageContext.data} />
      <SubpageBack target="/zespol/" />
    </Layout>
  )
}

export default Team
